import React from "react"

import notFoundStyles from './404.module.scss'
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Página não encontrada" />
    <div className={notFoundStyles.mainContent}>
      <h1 className={notFoundStyles.title}>Ops... 404</h1>
      <p className={notFoundStyles.description}>Deve haver algum engano. A página que você quer acessar não existe.</p>
    </div>
  </Layout>
)

export default NotFoundPage
